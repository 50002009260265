import React from "react";
import loadable from "@loadable/component";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
// import CodeBlock from "../components/atoms/code-block";
const CodeBlock = loadable(() => import("../components/atoms/code-block"));

function DesignSpacing() {
  return (
    <div className="design__content">
      <SEO title="Design System - Spacing" />
      <h2>Spacing</h2>
      <p className="design__text p2">
        Spacing is defined by margins and/or padding above and below type and
        other components. Spacing guidelines are to properly separate sections
        and pieces of content. Below are the space sizes used throughout Buffy.
      </p>

      <div className="design__stories">
        <div className="design__story-section">
          <h5>Component Spacing</h5>
          <p className="design__text p2">
            Components can be spaced with either margin or padding applied to
            the top and/or bottom.
          </p>
          <DesignStory
            title="micro"
            type="space-component"
            compact
            props={{ className: "micro" }}
          />
          <DesignStory
            title="x-small"
            type="space-component"
            compact
            props={{ className: "xs" }}
          />
          <DesignStory
            title="small"
            type="space-component"
            compact
            props={{ className: "s" }}
          />
          <DesignStory
            title="medium"
            type="space-component"
            compact
            props={{ className: "m" }}
          />
          <DesignStory
            title="large"
            type="space-component"
            compact
            props={{ className: "l" }}
          />
          <DesignStory
            title="x-large"
            type="space-component"
            compact
            props={{ className: "xl" }}
          />

          <p className="design__text p2">
            Spacing beyond the x-large size can use the custom ltc-unit mixin.
            The below code will add a 200 pixel (25 x 8) top margin:
          </p>
          <CodeBlock language="scss">
            @include ltc-unit(&quot;margin-top&quot;, 25);
          </CodeBlock>
        </div>
        <div className="design__story-section">
          <h5>Typography Spacing</h5>
          <p className="design__text p2">
            Spacing can be applied to type by adding a class or using a sass
            mixin. Note that the micro or x-small spacing may not be applied
            visually due to existing padding and line-height.
          </p>
          <DesignStory
            title="h4"
            type="space"
            compact
            props={{ className: "micro" }}>
            micro
          </DesignStory>
          <DesignStory
            title="h4"
            type="space"
            compact
            props={{ className: "xs" }}>
            x-small
          </DesignStory>
          <DesignStory
            title="h4"
            type="space"
            compact
            props={{ className: "s" }}>
            small
          </DesignStory>
          <DesignStory
            title="h4"
            type="space"
            compact
            props={{ className: "m" }}>
            medium
          </DesignStory>
          <DesignStory
            title="h4"
            type="space"
            compact
            props={{ className: "l" }}>
            large
          </DesignStory>
          <DesignStory
            title="h4"
            type="space"
            compact
            props={{ className: "xl" }}>
            x-large
          </DesignStory>
        </div>

        <div className="design__story-section">
          <h5>Border &amp; Separator Spacing</h5>
          <p className="design__text p2">
            Spacing can be applied above or below lines and borders.
          </p>
          <DesignStory
            title="div"
            type="space-line"
            compact
            props={{ className: "micro", name: "micro (top)", pos: "top" }}
          />
          <DesignStory
            title="div"
            type="space-line"
            compact
            props={{
              className: "micro",
              name: "micro (bottom)",
              pos: "bottom",
            }}
          />
          <DesignStory
            title="div"
            type="space-line"
            compact
            props={{ className: "xs", name: "x-small (top)", pos: "top" }}
          />
          <DesignStory
            title="div"
            type="space-line"
            compact
            props={{ className: "xs", name: "x-small (bottom)", pos: "bottom" }}
          />
          <DesignStory
            title="div"
            type="space-line"
            compact
            props={{ className: "s", name: "small (top)", pos: "top" }}
          />
          <DesignStory
            title="div"
            type="space-line"
            compact
            props={{ className: "s", name: "small (bottom)", pos: "bottom" }}
          />
          <DesignStory
            title="div"
            type="space-line"
            compact
            props={{ className: "m", name: "medium (top)", pos: "top" }}
          />
          <DesignStory
            title="div"
            type="space-line"
            compact
            props={{ className: "m", name: "medium (bottom)", pos: "bottom" }}
          />
          <DesignStory
            title="div"
            type="space-line"
            compact
            props={{ className: "l", name: "large (top)", pos: "top" }}
          />
          <DesignStory
            title="div"
            type="space-line"
            compact
            props={{ className: "l", name: "large (bottom)", pos: "bottom" }}
          />
          <DesignStory
            title="div"
            type="space-line"
            compact
            props={{ className: "xl", name: "x-large (top)", pos: "top" }}
          />
          <DesignStory
            title="div"
            type="space-line"
            compact
            props={{ className: "xl", name: "x-large (bottom)", pos: "bottom" }}
          />
        </div>
      </div>
    </div>
  );
}

export default DesignSpacing;
